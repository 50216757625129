<template>
    <card-list>
        <subheader-list-item icon="fa-align-left" title="Event information"/>
        <container-list-item padded>
            <div class="columns">
                <div class="column">
                    <b-field label="Event Type" label-position="inside">
                        <b-select expanded v-model="event.type">
                            <option value="event">Event</option>
                            <option value="meal">Meal</option>
                            <option value="playing">Playing Tennis</option>
                            <option value="watching">Watching Tennis</option>
                            <option value="hotel">Hotel</option>
                            <option value="tour">Tour</option>
                            <option value="bus">Bus/Vehicle Transfer</option>
                            <option value="car">Private Transfer</option>
                            <option value="transfer">Other Transfer</option>
                        </b-select>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column is-8">
                    <b-field label="Title" label-position="inside">
                        <b-input v-model="event.title"></b-input>
                    </b-field>
                </div>

                <div class="column">
                    <b-field label="Location" label-position="inside">
                        <b-input v-model="event.location"/>
                    </b-field>
                </div>
            </div>
            <div class="columns" v-show="event.type === 'bus' || event.type === 'transfer' || event.type === 'tour' || event.type === 'car'">
                <div class="column">
                    <b-field label="Start/Pickup Location" label-position="inside">
                        <b-input v-model="event.start_location"/>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column is-4">
                    <b-field label="Start Date" label-position="inside">
                        <b-input type="date" v-model="event.start_date"/>
                    </b-field>
                </div>
                <div class="column is-3">
                    <b-field label="Start Time" label-position="inside">
                        <b-input type="time" v-model="event.start_time"/>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Timezone" label-position="inside">
                        <b-autocomplete
                            :data="startTzResults"
                            field="title"
                            v-model="event.start_tz"
                            @select="selectTZ"
                            icon="search"
                            open-on-focus
                            placeholder="Search...">

                            <template slot="empty">No results.</template>
                        </b-autocomplete>
                    </b-field>
                </div>
            </div>

            <div class="columns" v-show="event.type === 'bus' || event.type === 'transfer' || event.type === 'tour' || event.type === 'car'">
                <div class="column">
                    <b-field label="End/Drop-off Location" label-position="inside">
                        <b-input v-model="event.end_location"/>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column is-4">
                    <b-field label="End Date" label-position="inside">
                        <b-input type="date" v-model="event.end_date"/>
                    </b-field>
                </div>
                <div class="column is-3">
                    <b-field label="End Time" label-position="inside">
                        <b-input type="time" v-model="event.end_time"/>
                    </b-field>
                </div>

                <div class="column">
                    <b-field label="Timezone" label-position="inside">
                        <b-autocomplete
                            :data="endTzResults"
                            field="title"
                            v-model="event.end_tz"
                            @select="selectTZ"
                            icon="search"
                            open-on-focus
                            placeholder="Search...">

                            <template slot="empty">No results.</template>
                        </b-autocomplete>
                    </b-field>
                </div>
            </div>
            <div class="columns" v-if="!custom">
                <div class="column">
                    <b-switch :true-value="1" :false-value="0" v-model="event.is_hidden">Hidden on Website</b-switch>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Description" label-position="inside">
                        <b-input type="textarea" v-model="event.description"/>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Critical Participant Information" label-position="inside">
                        <b-input v-model="event.critical_info"/>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Vendor Information">
                        <quill-editor v-model="event.vendor_info" ref="vendorEditor" :options="editorOptions" class="has-background-white content"/>
                    </b-field>
                </div>
            </div>
        </container-list-item>
        <subheader-list-item icon="fa-file-pdf" title="Documents"/>
        <container-list-item padded v-if="!event.id">
            <i class="far fa-info-circle mr-3 ml-2 fa-fw"/>Please save this event before adding documents.
        </container-list-item>

        <template v-else>
            <document-list-item
                v-if="!custom"
                v-for="doc in event.documents"
                :doc="doc"
                :card="card"
                :active="(child && child.definition.component === 'edit-event-document') && child.props.docId === doc.id.toString()"
                @click="$openCard('edit-event-document', {tripId: tripId, dayId: event.day_id, eventId: event.id, docId: doc.id}, card)"
            />
            <document-list-item
                v-else
                v-for="doc in event.documents"
                :doc="doc"
                :card="card"
                :active="(child && child.definition.component === 'edit-event-document') && child.props.docId === doc.id.toString()"
                @click="$openCard('edit-event-document', {tripId: 'null', dayId: event.day_id, eventId: event.id, docId: doc.id, type: 'custom-event', registrationId: registrationId}, card)"
            />
        </template>


        <container-list-item padded v-if="event.id && event.documents.length === 0">
            <i class="far fa-info-circle mr-3 ml-2 fa-fw"/>No event documents.
        </container-list-item>

        <subheader-list-item icon="fa-tasks" title="Task"/>
        <container-list-item padded>
            <div class="columns">
                <div class="column">
                    <b-field label="Status" label-position="inside">
                        <b-select expanded v-model="event.task_status">
                            <option value="planned">Planned</option>
                            <option value="booked">Booked</option>
                            <option value="confirmed">Confirmed</option>
                        </b-select>
                    </b-field>
                </div>
                <div class="column">
                    <b-field label="Payment" label-position="inside">
                        <b-select expanded v-model="event.task_payment">
                            <option value="unpaid">Unpaid</option>
                            <option value="deposit">Deposit</option>
                            <option value="paid">Paid</option>
                            <option value="na">N/A</option>
                        </b-select>
                    </b-field>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Assignment" label-position="inside">
                        <b-select expanded v-model="event.task_assigned_id">
                            <option :value="a.id" v-for="a in assignees">{{ a.first_name }} {{ a.last_name }}</option>
                        </b-select>
                    </b-field>
                </div>
                <div class="column is-4">
                    <b-switch :native-value="1" :true-value="1" :false-value="0" v-model="event.task_complete">Complete/Not Tracked</b-switch>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <b-field label="Notes" label-position="inside">
                        <b-input type="textarea" v-model="event.task_notes"/>
                    </b-field>
                </div>
            </div>
        </container-list-item>
    </card-list>
</template>

<script>
    import CardList from '../../TIER/components/CardList';
    import SubheaderListItem from '../../TIER/components/SubheaderListItem';
    import ContainerListItem from '../../TIER/components/ContainerListItem';

    import moment from 'moment-timezone';
    import {mapGetters} from "vuex";
    import DocumentListItem from "../../components/DocumentListItem.vue";

    export default {
        name: 'EventForm',
        props: {
            event: {
                type: Object,
                required: true
            },
            tripId: {
                type: Number,
                required: false
            },
            registrationId: {
                type: Number,
                required: false
            },
            custom: {
                type: Boolean,
                default: false
            },
            card: {
                type: Object,
                required: true
            },
            child: {
                required: true
            }
        },
        components: {DocumentListItem, ContainerListItem, SubheaderListItem, CardList},
        data: function() {
            return {
                zones: moment.tz.names(),
                editorOptions: {}
            };
        },
        computed: {
            ...mapGetters(['assignees']),
            startTzResults() {
                if (this.event && this.event.start_tz) {
                    return this.zones.filter((option) => {
                        return option
                            .toString()
                            .toLowerCase()
                            .indexOf(this.event.start_tz.toLowerCase()) >= 0;
                    });
                } else {
                    return null;
                }
            },
            endTzResults() {
                if (this.event && this.event.end_tz) {
                    return this.zones.filter((option) => {
                        return option
                            .toString()
                            .toLowerCase()
                            .indexOf(this.event.end_tz.toLowerCase()) >= 0;
                    });
                } else {
                    return null;
                }
            }
        },
        watch: {
            'event.type': function(val) {
                if (val === 'car' && !this.event.description ) {
                    this.event.title = 'Private Car/Van Transfer';
                    this.event.description = `We have booked a car/van and driver to take you from the airport to the hotel. Please meet them in the arrivals hall at the airport. They will have a sign with your name on it.`;
                }
            }
        },
        methods: {
            selectTZ(option) {
                // console.log('Selected', moment().tz(option).format('MMMM Do YYYY, h:mm:ss a Z'));
            }
        }
    };
</script>
