<template>
    <div>
        <card-header title="Edit Custom Event" icon="fa-pencil"/>
        <card-toolbar>
            <button @click="$openCard('new-event-document', {...props, tripId: 'null', type: 'custom-event'}, card)"><i class="fas fa-plus mr-2"/>New Document</button>
        </card-toolbar>
        <card-body>
            <card-list>
                <registration-list-item :card="card" :registration="registration"/>
            </card-list>
            <event-form :event="event" :card="card" :child="child" :registration-id="registration.id" custom/>
        </card-body>

        <card-footer class="pt-3 pb-3">
            <div class="columns">
                <div class="column">
                    <b-button type="is-warning is-outlined" size="is-small" @click="deleteEvent"><i class="far fa-trash-alt"></i><span class="ml-2" v-if="confirmDelete">Delete Custom Event?</span></b-button>
                </div>
                <div class="column has-text-right">
                    <b-button type="is-primary" size="is-small" @click="submit"><i class="fas fa-check mr-2"></i>Save Event</b-button>
                </div>
            </div>
        </card-footer>
    </div>

</template>

<script>
    import async from "async";
    import {client as http} from "../../http_client";
    import CardHeader from "../../TIER/components/CardHeader";
    import CardBody from "../../TIER/components/CardBody";
    import CardFooter from "../../TIER/components/CardFooter";
    import EventForm from "../trips/EventForm";
    import RegistrationListItem from "../../components/RegistrationListItem";
    import CardList from "../../TIER/components/CardList";
    import {mapActions} from "vuex";
    import CardToolbar from "../../TIER/components/CardToolbar.vue";

    export default {
        components: {CardToolbar, CardList, RegistrationListItem, EventForm, CardFooter, CardBody, CardHeader},
        props: ['card', 'props', 'child'],
        data: function() {
            return {
                event: {},
                registration: {
                    trip: {},
                    user: {}
                },
                confirmDelete: false,
                dirty: null
            }
        },
        methods: {
            ...mapActions(['updateTasks']),
            loadEvent: function() {
                this.$emit('loading', true);
                this.dirty = null;

                async.series([
                    // Get event
                    (cb) => {
                        http.get('/api/registrations/' + this.props.registrationId + '/events/' + this.props.dayId + '/' + this.props.eventId, {force: true}).then(response => {
                            this.event = response.data;
                            cb();
                        });
                    },
                    // Get registration
                    (cb) => {
                        http.get('/api/registrations/' + this.props.registrationId, {force: true}).then(response => {
                            this.registration = response.data;
                            cb();
                        });
                    }
                ], err => {
                    this.$emit('loading', false);
                });
            },
            submit() {
                this.$emit('loading', true);

                http.put('/api/registrations/' + this.props.registrationId + '/events/' + this.props.eventId, this.event, {force: true}).then(response => {
                    this.updateTasks();
                    this.$reloadCard('registrations');
                    this.$reloadCard('registration', {registrationId: this.props.registrationId});
                    this.$reloadCard('itinerary', {registrationId: this.props.registrationId});
                    this.$reloadCard('events', {dayId: this.props.dayId});
                    this.$reloadCard('tasks');
                    this.$reloadCard('task-details', {type: 'reg-events'});

                    this.$saveAlert(false);
                    this.$closeCard(this.card);
                }).catch(err => {

                });
            },
            deleteEvent() {
                if (this.confirmDelete) {
                    this.$emit('loading', true);

                    http.delete('/api/registrations/' + this.props.registrationId + '/events/' + this.props.eventId).then(response => {
                        this.$reloadCard('tasks');
                        this.$reloadCard('events', {dayId: this.props.dayId});
                        this.$saveAlert(false);
                        this.$closeCard(this.card);
                    }).catch(err => {

                    });
                } else {
                    this.confirmDelete = true;
                    setTimeout(() => {
                        this.confirmDelete = false;
                    }, 3000);
                }
            },
            reload() {
                this.$emit('loading', true);

                http.get('/api/registrations/' + this.props.registrationId + '/events/' + this.props.dayId + '/' + this.props.eventId, {force: true}).then(response => {
                    this.event.documents = response.data.documents;
                    this.$emit('loading', false);
                });
            }
        },
        watch: {
            'props.eventId': function() {
                this.loadEvent();
            },
            'event': {
                deep: true,
                handler: function () {
                    this.dirty = (this.dirty !== null);
                    if (!this.$TIER.saveAlert.active && this.dirty)
                        this.$saveAlert(true, 'Are you done editing this event? Unsaved changes will be lost.');
                }
            }
        },
        mounted() {
            this.loadEvent();
            console.log(this.props);
        }
    };
</script>

<style scoped>

</style>
